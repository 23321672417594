import '../scss/newsletter.scss';
import { post } from './utils/api'

/**
 * Initializes the newsletter modal on page load and controls its appearance timing based on the user's localStorage settings.
 *
 * - Shows the modal after a specific number of seconds.
 *
 * @event DOMContentLoaded Triggers when the DOM has fully loaded.
 */
document.addEventListener('DOMContentLoaded', () => {
    const modalElement = document.getElementById('newsletter');
    const secondsToShow = parseInt(modalElement.dataset.seconds);
    const daysToReshow = localStorage.getItem('newsletter_reshow');
    const modal = new window.bootstrap.Modal(modalElement);

    if (modalElement && daysToReshow < Date.now()) {
        setTimeout(() => {
            modal.show();
        }, secondsToShow * 1000);

        handleSubmit(modalElement);
        handleToc(modalElement);
    }
});

/**
 * Event handler for when the Bootstrap modal is fully shown. Updates the localStorage value for when the modal should be shown again.
 *
 * @event shown.bs.modal Triggered when the modal is fully shown.
 * @param {Event} event - The event object associated with the modal display.
 */
document.addEventListener('shown.bs.modal', (event) => {
    const storageKey = 'newsletter_reshow';
    const daysToReshow = event.target.dataset.days;
    const DAY_TO_MILLIS = 86400000;

    localStorage.setItem(storageKey, Date.now() + (daysToReshow * DAY_TO_MILLIS));
});

/**
 * Handles the newsletter form submission by sending the form data to the server and displaying success or error messages.
 *
 * @param {HTMLElement} modalElement - The modal element containing the newsletter form and message elements.
 */
function handleSubmit(modalElement) {
    const newsletterForm = modalElement.querySelector('#newsletter-form');
    const newsletterMessage = modalElement.querySelector('#newsletter-message');

    newsletterForm.addEventListener('submit', (event) => {
        event.preventDefault();

        const modal = bootstrap.Modal.getInstance(modalElement);
        const email = newsletterForm.querySelector('[name="email"]').value;
        const listId = newsletterForm.querySelector('[name="list_id"]').value;

        post('/api/v1/newsletters', {
            email: email,
            list_id: listId
        }).then(response => {
            if (response.status === 200) {
                newsletterMessage.innerHTML = response.data.message;
                newsletterMessage.classList.remove('d-none');

                setTimeout(() => {
                    modal.hide();
                }, 3000);
            } else {
                newsletterMessage.innerHTML = response.messages.message;
                newsletterMessage.classList.remove('d-none');
            }
        });
    });
}

/**
 * Handles the toggle of the Terms of Conditions (ToC) checkbox in the newsletter modal.
 * When the checkbox is checked, it enables the submit button; otherwise, it disables it.
 *
 * @param {HTMLElement} modalElement - The modal element containing the ToC checkbox and submit button.
 */
function handleToc(modalElement) {
    const tocCheckbox = modalElement.querySelector('#newsletter-modal-toc');
    const submitButton = modalElement.querySelector('#newsletter-button');

    tocCheckbox.addEventListener('change', () => {
        submitButton.toggleAttribute('disabled');
    });
}
